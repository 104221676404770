import React, { useEffect, useState } from "react";
import { mic_down, mic_up } from "../../assets/images/images";
import useAudioRecorder from "../../hooks/useAudioRecorder";
import useTranscriptionStore from "../../zustand/speechToTextStore";
import { useSelector } from "react-redux";

const TranscriptionTest = ({ onDone }) => {
  const token = useSelector((state) => state?.auth?.token); // Retrieve token from the store
  const [testStarted, setTestStarted] = useState(false);
  const [hasReceivedInput, setHasReceivedInput] = useState(false);

  const { startRecording, stopRecording, isRecording, isSpeaking, audioBlob } =
    useAudioRecorder();

  const { transcript, isLoading, error, transcribeAudio, clearTranscript } =
    useTranscriptionStore();

  const handleButtonClick = () => {
    if (isRecording) {
      stopRecording();
    } else {
      clearTranscript(); // Clear any previous transcript
      setHasReceivedInput(false);
      startRecording();
      setTestStarted(true);
    }
  };

  useEffect(() => {
    if (isSpeaking) {
      // Indicate that the user is speaking
      clearTranscript();
    } else if (audioBlob) {
      // Convert audioBlob to base64 and trigger the transcription API call
      const reader = new FileReader();
      reader.readAsDataURL(audioBlob);
      reader.onloadend = async () => {
        const base64data = reader.result?.toString().split(",")[1];
        if (base64data) {
          await transcribeAudio(base64data, token);
          setHasReceivedInput(true);
        }
      };
    }
  }, [isSpeaking, audioBlob, transcribeAudio, clearTranscript, token]);

  useEffect(() => {
    // Stop recording when the component unmounts to avoid memory leaks
    return () => {
      stopRecording();
    };
  }, []);

  // Dynamic class and style for the 'Done' button
  const doneButtonClass =
    testStarted && hasReceivedInput
      ? "blue_bg_btn"
      : "blue_bg_btn disabled_button";
  const doneButtonStyle =
    testStarted && hasReceivedInput
      ? {}
      : { backgroundColor: "grey", cursor: "not-allowed" };

  const startButtonStyle =
    isLoading
      ? { backgroundColor: "grey", cursor: "not-allowed" }
      : {};

  return (
    <div className="InterviewModal_main">
      <div className="InterviewModal_main_inner">
        <div className="test_speaker">
          <h2>Test Your Microphone</h2>
        </div>
        <div>
          <p className="text-left listening">
            Listening: {isRecording ? "Yes" : "No"}
          </p>
          <p className="Transcript_box">
            {isLoading
              ? "Processing audio..."
              : transcript || "Ex: Hello, my name is John Doe."}
          </p>
          {error && <p className="error">Error: {error}</p>}
        </div>
      </div>
      <div className="space-bt">
        <div>
          <button
            className="blue_bg_btn"
            onClick={handleButtonClick}
            style={startButtonStyle}
            disabled={isLoading} // Disable button while loading
            tabIndex={0}
          >
            <img
              src={isRecording ? mic_down : mic_up}
              alt=""
              style={{ width: "20px", marginRight: "10px" }}
            />
            {isRecording ? "Stop Listening" : "Start Listening"}
          </button>
        </div>
        <button
          disabled={!testStarted || !hasReceivedInput || isLoading}
          className={doneButtonClass}
          style={doneButtonStyle}
          onClick={onDone}
        >
          Done
        </button>
      </div>
    </div>
  );
};

export default TranscriptionTest;
