import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Form, Input, Modal, Spin, Steps } from "antd";
import { MuiTelInput } from "mui-tel-input";
import { useCallback, useEffect, useState } from "react";
import { isValidPhoneNumber } from "react-phone-number-input";
import { useSelector } from "react-redux";
import instance from "../../api/instance";
import { debounceApi } from "../../utils/utils";
import userStore from "../../zustand/userStore";
import "./TryHaire.css";
import TryHaireOtp from "./TryHaireOtp";
import MuiPhoneNumber from "material-ui-phone-number";

const { Step } = Steps;

const TryHaire = () => {
  const [modalOpen, setModalOpen] = useState(true);
  const [currentStep, setCurrentStep] = useState(0);
  const [form] = Form.useForm();
  const [formValues, setFormValues] = useState({
    companyName: "",
    industryType: "",
    jobProfile: "",
    fullName: "",
    email: "",
    phoneNumber: "",
  });
  const [otherIndustry, setOtherIndustry] = useState("");
  const [otpStep, setOtpStep] = useState(false);
  const [finalFormValues, setFinalFormValues] = useState({});
  const [touchedFields, setTouchedFields] = useState({});
  const [phoneError, setPhoneError] = useState(""); // Track phone number error
  const loading = userStore((state) => state.loading);
  const [isPhoneValid, setIsPhoneValid] = useState(false);
  const location = useSelector((state) => state?.location?.userCountry);
  const registerWithEmailPassword = userStore(
    (state) => state.registerWithEmailPassword
  );

  const next = () => {
    if (isStepValid()) {
      setCurrentStep(currentStep + 1);
    }
  };

  const goToFirstStep = () => {
    setCurrentStep(0);
  };

  const goToStep2 = () => {
    setOtpStep(false);
    setModalOpen(true);
    setCurrentStep(1);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    let trimmedValue = value.trim();
    if (name === "jobProfile" && trimmedValue.length > 40) {
      trimmedValue = trimmedValue.slice(0, 40);
    }

    if (name === "industryType") {
      if (value === "other") {
        setFormValues({ ...formValues, industryType: value });
      } else {
        setFormValues({ ...formValues, industryType: value });
        setOtherIndustry("");
      }
    } else if (name === "otherIndustry") {
      setOtherIndustry(value);
    } else {
      setFormValues({ ...formValues, [name]: value });
    }

    setTouchedFields({ ...touchedFields, [name]: true });
  };

  const handlePhoneNumberChange = (value) => {
    if (!value) {
      // If the input is cleared, reset the phone number and the error
      setFormValues({ ...formValues, phoneNumber: "" });
      setPhoneError("");
      setIsPhoneValid(false);
    } else if (isValidPhoneNumber(value)) {
      // Valid phone number
      setPhoneError("");
      setFormValues({ ...formValues, phoneNumber: value });
      debounceCheckPhoneExists(value); // Debounce the API call
    } else {
      // Invalid phone number
      setPhoneError("Please enter a valid contact number.");
      setIsPhoneValid(false);
    }
  };


  // Debounce function
  const debounceCheckPhoneExists = useCallback(
    debounceApi(async (phone) => {
      try {
        const response = await instance.post("/accounts/check-phone-exists/", {
          phone: phone,
        });

        if (response.data.message === "User not found.") {
          setPhoneError("");
          setIsPhoneValid(true);
        } else {
          setPhoneError(
            "Phone number already exists. Please use a different number."
          );
          setIsPhoneValid(false);
        }
      } catch (error) {
        setPhoneError(
          "Phone number already exists. Please use a different number."
        );
        setIsPhoneValid(false);
      }
    }, 500),
    []
  );

  const validateField = (field, value) => {
    // Check if the field is required and has no value
    if (field.rules?.some((rule) => rule.required && !value)) {
      return false;
    }

    // Check if the field's pattern is valid
    if (
      field.rules?.some(
        (rule) => rule.pattern && value && !rule.pattern.test(value)
      )
    ) {
      return false;
    }

    // Check for specific fields with empty values
    if (
      ["jobProfile", "companyName", "fullName"].includes(field.name) &&
      value.trim() === ""
    ) {
      return false;
    }

    return true;
  };

  const isStepValid = () => {
    const currentFields = stepsContent[currentStep].formFields;

    // Check if all fields in the current step are valid
    const areFieldsValid = currentFields.every((field) =>
      validateField(field, formValues[field.name])
    );

    // If on the second step, also check the phone validation
    if (currentStep === 1) {
      return areFieldsValid && !phoneError && isPhoneValid;
    }

    if (
      currentStep === 0 &&
      formValues.industryType === "other" &&
      !otherIndustry.trim()
    ) {
      return false;
    }

    return areFieldsValid;
  };

  useEffect(() => {
    isStepValid();
  }, [isPhoneValid]);

  const handleSubmit = () => {
    const finalValues = {
      ...formValues,
      industryType:
        formValues.industryType === "other"
          ? otherIndustry
          : formValues.industryType,
    };
    setFinalFormValues(finalValues);
    if (isStepValid()) {
      registerWithEmailPassword(finalValues.email, "defaultPassword123", () => {
        setModalOpen(false);
        setOtpStep(true);
        startTimer();
      });
    }
  };

  const [timer, setTimer] = useState(60);
  const startTimer = () => {
    setTimer(60);
    const interval = setInterval(() => {
      setTimer((prevTimer) => {
        if (prevTimer <= 0) {
          clearInterval(interval);
          return 0;
        }
        return prevTimer - 1;
      });
    }, 1000);
  };

  const stepsContent = [
    {
      title: "Step 1",
      header: (
        <>
          <h6 className="mdl-h6">Let’s personalize this demo for you.</h6>
          <p className="verification-modal-subtitle mdl-p">
            We have a few questions about your company and hiring needs.
          </p>
        </>
      ),
      formFields: [
        {
          name: "companyName",
          label: "Company Name",
          placeholder: "Enter company name",
          rules: [
            { required: true, message: "Please enter your company name" },
            {
              max: 60,
              message: "Company name cannot exceed 60 characters",
            },
            {
              validator: (_, value) => {
                if (value && value.trim() === "") {
                  return Promise.reject(
                    new Error("Company name cannot be only spaces")
                  );
                }
                return Promise.resolve();
              },
            },
          ],
        },
        {
          name: "industryType",
          label: "Industry Type",
          placeholder: "Select industry type",
          type: "select",
          rules: [
            { required: true, message: "Please select an industry type" },
          ],
        },
        {
          name: "jobProfile",
          label: "Job Profile",
          placeholder: "Enter job profile",
          rules: [
            { required: true, message: "Please enter your job profile" },
            {
              max: 40,
              message: "Job profile cannot exceed 40 characters",
            },
            {
              validator: (_, value) => {
                if (value && value.trim() === "") {
                  return Promise.reject(
                    new Error("Job Profile cannot be only spaces")
                  );
                }
                return Promise.resolve();
              },
            },
          ],
        },
      ],
    },
    {
      title: "Step 2",
      header: (
        <>
          <button className="back-button2" onClick={goToFirstStep}>
            <FontAwesomeIcon icon={faArrowLeft} />
          </button>
          <h6 className="mdl-h6">Verify information to start demo.</h6>
          <p className="verification-modal-subtitle mdl-p">
            We need to collect a few basic personal details from you.
          </p>
        </>
      ),
      formFields: [
        {
          name: "fullName",
          label: "Full Name",
          placeholder: "Enter full name",
          rules: [
            { required: true, message: "Please enter your full name" },
            {
              pattern: /^[a-zA-Z\s'-]+$/,
              message:
                "Full name can only contain letters, spaces, hyphens, and apostrophes",
            },
            {
              validator: (_, value) => {
                if (value && value.trim() === "") {
                  return Promise.reject(
                    new Error("Full name cannot be only spaces")
                  );
                }
                return Promise.resolve();
              },
            },
          ],
        },
        {
          name: "email",
          label: "Business Email",
          placeholder: "Enter business email",
          rules: [
            {
              required: true,
              validator: (_, value) => {
                const emailRegex =
                  /^[^\s@]+@[^\s@]+\.(com|ai|net|org|edu|gov|mil|biz|info|mobi|name|aero|asia|jobs|museum|[a-z]{2})$/i;
                const forbiddenDomains = [
                  "gmail.com",
                  "outlook.com",
                  "yahoo.com",
                ];
                const emailDomain = value?.split("@")[1]?.toLowerCase();

                if (!value) {
                  return Promise.reject(new Error("Please enter your email"));
                }

                if (!emailRegex.test(value)) {
                  return Promise.reject(new Error("Invalid email format"));
                }

                if (forbiddenDomains.includes(emailDomain)) {
                  return Promise.reject(
                    new Error("This is not a Business Email")
                  );
                }

                return Promise.resolve();
              },
            },
          ],
        },

        {
          name: "phoneNumber",
          label: "Contact Number",
          placeholder: "Enter contact number",
          type: "phone",
          rules: [
            { required: true, message: "Please enter your contact number" },
          ],
        },
      ],
    },
  ];

  return (
    <>
      <div className="fullscreen-bg"></div>
      <div className="content">
        <Modal
          open={modalOpen}
          footer={null}
          className="verficiation_mod"
          id="Verficiation_mod"
          closeIcon={null}
          styles={{ height: loading ? "468px" : "auto", overflow: "auto" }}
        >
          {loading ? (
            <div className="loader-cust">
              <Spin size="large" className="modal-loader" />
            </div>
          ) : (
            <>
              {stepsContent[currentStep].header}
              <Steps current={currentStep} className="steps-container">
                {stepsContent.map((step, index) => (
                  <Step key={index} />
                ))}
              </Steps>
              <div className="steps-content">
                <Form layout="vertical" form={form}>
                  {stepsContent[currentStep].formFields.map((field) => (
                    <Form.Item
                      label={field.label}
                      name={field.name}
                      key={field.name}
                      rules={field.rules}
                    >
                      {field.type === "select" ? (
                        <>
                          <select
                            className="custom-select2"
                            name={field.name}
                            value={formValues[field.name]}
                            onChange={handleInputChange}
                          >
                            <option value="">{field.placeholder}</option>
                            <option value="it">IT</option>
                            <option value="finance">Finance</option>
                            <option value="healthcare">Healthcare</option>
                            <option value="education">Education</option>
                            <option value="other">Others</option>
                          </select>
                          {formValues.industryType === "other" && (
                            <Input
                              placeholder="Please specify your industry"
                              value={otherIndustry}
                              onChange={(e) =>
                                handleInputChange({
                                  target: {
                                    name: "otherIndustry",
                                    value: e.target.value,
                                  },
                                })
                              }
                              name="otherIndustry"
                              style={{ marginTop: "8px" }}
                            />
                          )}
                        </>
                      ) : field.type === "phone" ? (
                        <>
                          <MuiPhoneNumber
                            name={field.name}
                            variant="filled"
                            defaultCountry={location}
                            value={formValues.phoneNumber}
                            onChange={handlePhoneNumberChange}
                            className="custom-phone-input1"
                            required
                            fullWidth
                            InputProps={{
                              disableUnderline: true,
                            }}
                            disabled={loading}
                            sx={{
                              height: 42,
                              "& .MuiInputBase-input": {
                                height: "30px",
                                padding: "6px 14px",
                              },
                              "& .MuiFilledInput-root": {
                                borderRadius: "4px",
                                fontSize: "14px",
                                fontWeight: 400,
                                fontFamily: "inherit",
                                background: "rgba(248, 248, 248, 1)",
                              },
                            }}
                          />
                          {phoneError && (
                            <div className="ant-form-item-explain-error">
                              {phoneError}
                            </div>
                          )}
                        </>
                      ) : (
                        <Input
                          name={field.name}
                          placeholder={field.placeholder}
                          value={formValues[field.name]}
                          onChange={handleInputChange}
                          onBlur={() =>
                            setTouchedFields({
                              ...touchedFields,
                              [field.name]: true,
                            })
                          }
                          maxLength={
                            field.name === "jobProfile"
                              ? 40
                              : field.name === "companyName"
                                ? 60
                                : field.name === "fullName"
                                  ? 70
                                  : undefined
                          }
                        />
                      )}
                    </Form.Item>
                  ))}
                </Form>

                <div className="steps-action">
                  {currentStep < stepsContent.length - 1 && (
                    <Button
                      type="primary"
                      onClick={next}
                      disabled={!isStepValid()}
                      className={!isStepValid() ? "disabled-btn" : ""}
                    >
                      Next
                    </Button>
                  )}
                  {currentStep === stepsContent.length - 1 && (
                    <Button
                      type="primary"
                      disabled={!isStepValid()}
                      className={!isStepValid() ? "disabled-btn" : ""}
                      onClick={handleSubmit}
                    >
                      Submit
                    </Button>
                  )}
                </div>
              </div>
            </>
          )}
        </Modal>
        {otpStep && (
          <TryHaireOtp
            timer={timer}
            setTimer={setTimer}
            startTimer={startTimer}
            formData={finalFormValues}
            closeModal={() => setOtpStep(false)}
            goToStep2={goToStep2}
          />
        )}
      </div>
    </>
  );
};

export default TryHaire;
