import {
  CheckOutlined,
  DeleteOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { Button, Form, Input, Modal, Select, Tooltip } from "antd";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateJobDetails } from "../../../../redux/reducers/jobReducer";
import { isEmpty, renderFeatures } from "../../../../utils/utils";
import { tick } from "../../../../assets/images/images";
import { fetchAddons, updateAddon } from "../../../../redux/actions/jobActions";
import { toast } from "react-toastify";

const InterviewBudgetControl = ({ form }) => {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.token);
  const addons = useSelector((state) => state.job?.addons);
  const isPremium = useSelector((state) => state.company?.details?.is_premium);

  const [dailyBudget, setDailyBudget] = useState();
  const [budgetType, setBudgetType] = useState();
  const [error, setError] = useState("");
  const [dateError, setDateError] = useState("");
  const [refresh, setRefresh] = useState(false);

  const companyDetails = useSelector((state) => state.company?.details);
  const initialValues = useSelector((state) => state.job?.details);

  useEffect(() => {
    if (!isEmpty(initialValues)) {
      form.setFieldsValue({
        credits_alloted: initialValues.credits_alloted,
        budgetType: initialValues.budgetType || 2,
        custom_end_date: initialValues.custom_end_date,
      });
      setDailyBudget(initialValues.credits_alloted || 50);
    }
  }, [initialValues, form]);

  const handleBudgetChange = (event) => {
    const value = event.target.value;
    if (/^\d*$/.test(value)) {
      const numericValue = Number(value);
      if (numericValue < 2) {
        setError("At least 2 credits should be allotted.");
      } else {
        setError(""); // Clear the error if the value is valid
        setDailyBudget(numericValue);
        form.setFieldsValue({ credits_alloted: numericValue });
        if (numericValue > (companyDetails?.company_credits ?? 0)) {
          setError(
            `Insufficient credits: Your budget cannot exceed ${
              companyDetails?.company_credits ?? 0
            } Credits for this job`
          );
        }
      }
    } else {
      setError("Please enter only positive numbers.");
    }
  };
  //     setDailyBudget(numericValue);
  //     form.setFieldsValue({ credits_alloted: numericValue });
  //     if (numericValue > (companyDetails?.company_credits ?? 0)) {
  //       setError(
  //         `Insufficient credits: Your budget cannot exceed ${
  //           companyDetails?.company_credits ?? 0
  //         } Credits for this job`
  //       );
  //     } else {
  //       setError("");
  //     }
  //   } else {
  //     setError("Please enter only positive numbers.");
  //   }
  // };

  const handleBudgetTypeChange = (value) => {
    setBudgetType(value);
    form.setFieldsValue({ budgetType: value });
  };

  const handleDateChange = (date) => {
    const formattedDate = date ? dayjs(date).format("YYYY-MM-DD") : "";
    if (!dayjs(date).isValid()) {
      setDateError("Invalid date format. Please select a valid date.");
      form.setFieldsValue({ custom_end_date: "" });
    } else if (date && dayjs(date).isBefore(dayjs().startOf("day"))) {
      setDateError("Please select a current or future date.");
      form.setFieldsValue({ custom_end_date: "" });
    } else {
      setDateError("");
      form.setFieldsValue({ custom_end_date: formattedDate });
      dispatch(
        updateJobDetails({
          ...form.getFieldsValue(),
          custom_end_date: formattedDate,
        })
      );
    }
  };

  const handleSubmit = (values) => {
    if (error !== "" || dateError !== "" || dailyBudget < 0) {
      return; // Don't dispatch if there are errors
    } else {
      dispatch(updateJobDetails(values));
      dispatch(updateJobDetails(values));
    }
  };

  useEffect(() => {
    dispatch(fetchAddons(token));
  }, [dispatch, token, refresh]);

  const handleRemoveAddon = async (addonId) => {
    // Show a warning modal
    Modal.confirm({
      title: "Are you sure?",
      content:
        "This will remove all custom interview questions. Do you want to proceed?",
      okText: "Yes",
      cancelText: "No",
      onOk: () => {
        // If confirmed, remove the addon and reset custom_questions
        const currentAddons = form.getFieldValue("add_ons") || "";
        const addonIds = currentAddons ? currentAddons.split(",") : [];
        const updatedAddonIds = addonIds.filter(
          (id) => id !== addonId.toString()
        );
        const updatedAddons = updatedAddonIds.join(",");
        form.setFieldsValue({
          add_ons: updatedAddons,
        });

        // Reset custom_questions to contain one empty string
        const updatedValues = {
          ...form.getFieldsValue(),
          custom_questions: [""],
        };
        form.setFieldsValue(updatedValues);

        dispatch(updateJobDetails(updatedValues));
        toast.warning("Custom interview questions have been reset.");
        setRefresh((prev) => !prev);
      },
    });
  };

  const handleAddAddon = (addonId) => {
    const currentAddons = form.getFieldValue("add_ons") || "";
    const addonIds = currentAddons ? currentAddons.split(",") : [];
    if (!addonIds.includes(addonId.toString())) {
      addonIds.push(addonId.toString());
    }
    const updatedAddons = addonIds.join(",");
    form.setFieldsValue({
      add_ons: updatedAddons,
    });
    const updatedValues = form.getFieldsValue();
    console.log("Updated form values before dispatch:", updatedValues);
    dispatch(updateJobDetails(updatedValues));
  };

  const selectAfter = (
    <Form.Item name="budget">
      <Select
        defaultValue={2}
        value={budgetType}
        onChange={handleBudgetTypeChange}
        options={[
          {
            value: 2,
            label: "Total",
          },
          {
            value: 1,
            label: "Daily",
          },
        ]}
      />
    </Form.Item>
  );

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <section className="form_sec_new_inner_first normalize_padding">
        <div className="Monitization_section_credit_section">
          <div className="Monitization_section_credit_section-left">
            <Form
              form={form}
              onFinish={handleSubmit}
              onValuesChange={(changedValues, allValues) => {
                dispatch(updateJobDetails(allValues));
              }}
              id="job_form3"
            >
              <div className="credit_sec_main">
                <h2 className="head_sec_main">Allot Credits *</h2>
                <p className="head_sec_main_p">
                  We recommend 50 credits based on your job description.{" "}
                  <Tooltip title="We recommend 50 credits based on your job description">
                    <Button type="text" icon={<InfoCircleOutlined />} />
                  </Tooltip>
                </p>
                <Form.Item
                  name="credits_alloted"
                  validateStatus={error ? "error" : ""}
                  help={error}
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input
                    type="number"
                    min={2}
                    required
                    addonAfter={selectAfter}
                    value={dailyBudget}
                    onChange={handleBudgetChange}
                    placeholder="Enter your daily budget"
                    maxLength={10}
                    className="Add_credit"
                    style={{ fontSize: "16px", fontWeight: "900" }}
                  />
                </Form.Item>
                {initialValues.budget === 1 ? (
                  <>
                    <h2 className="head_sec_main">Custom End Date *</h2>
                    <p className="head_sec_main_p">
                      Until when would you like to allocate credits for AI
                      interviews?{" "}
                    </p>
                    <Form.Item
                      name="custom_end_date"
                      className="Date_cust"
                      validateStatus={dateError ? "error" : ""}
                      help={dateError}
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <DemoContainer components={["DatePicker"]}>
                        <DemoItem>
                          <DatePicker
                            value={
                              form.getFieldValue("custom_end_date")
                                ? dayjs(form.getFieldValue("custom_end_date"))
                                : null
                            }
                            disablePast
                            onChange={handleDateChange}
                          />
                        </DemoItem>
                      </DemoContainer>
                    </Form.Item>
                    <div className="list-features">
                      <h6>Features:</h6>
                      <ul className="list-features_ul">
                        {renderFeatures(companyDetails?.company_sub?.type).map(
                          (feature, index) => (
                            <li key={index}>
                              <img src={tick} alt="" className="tick" />{" "}
                              {feature}
                            </li>
                          )
                        )}
                      </ul>
                    </div>
                  </>
                ) : (
                  <>
                    <p className="head_sec_main_p green_p">
                      With 50 credits, you can schedule interviews until they're
                      exhausted, totaling approximately 17 - 19 interviews at 30
                      minutes each.
                    </p>
                    <p className="head_sec_main_p green_p">
                      With 50 credits, you can schedule around 17 - 19 daily
                      interviews at 30 minutes each.
                    </p>
                  </>
                )}
                <Form.Item name="add_ons" hidden>
                  <Input type="hidden" />
                </Form.Item>
              </div>
              <div className="Monitization_section_credit_section-right">
                <div>
                  <h4 className="head_sec_main">Add Ons:</h4>
                  {Array.isArray(addons) && addons.length > 0 ? (
                    addons.map((addon) => {
                      const currentAddons = form.getFieldValue("add_ons") || "";
                      const addonIds = currentAddons
                        ? currentAddons.split(",")
                        : [];
                      const isAddonPresent = addonIds.includes(
                        addon.id.toString()
                      );

                      return (
                        <div key={addon.id} className="bg-blue1 bg-blue1-card">
                          <h5 className="head_sec_main_p first">
                            {addon.feature_name}
                          </h5>
                          <p className="head_sec_main_p wdt_p">
                            {addon.feature_description}
                          </p>
                          <div className="add_credit_btn">
                            <p className="head_sec_main">
                              {addon.credits} Credits <span>per interview</span>
                            </p>
                            <button
                              className="Add_button_credit"
                              disabled={isAddonPresent ? true : false}
                              onClick={() => handleAddAddon(addon.id)}
                            >
                              {isAddonPresent ? (
                                <>
                                  <CheckOutlined /> Added
                                </>
                              ) : (
                                "Add"
                              )}
                            </button>

                            {isAddonPresent && (
                              <Button
                                type="text"
                                icon={<DeleteOutlined />}
                                onClick={() => handleRemoveAddon(addon.id)}
                              />
                            )}
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <p>No add-ons available.</p>
                  )}
                </div>
              </div>
            </Form>
          </div>
        </div>
      </section>
    </LocalizationProvider>
  );
};

export default InterviewBudgetControl;
